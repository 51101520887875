body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='tel' i] {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

input[type='tel' i]:focus {
  border-color: #4096ff;
  box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
  border-inline-end-width: 1px;
  outline: 0;
}

input[type='tel' i]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bfbfbf;
  opacity: 1; /* Firefox */
}

input[type='tel' i]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bfbfbf;
}

input[type='tel' i]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bfbfbf;
}
